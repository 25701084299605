$(window).on("load", function () {
  if (_ua.Mobile) {
  } else {
    /* 
    var scroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
      //multiplier: 0.5 // スクロールの速度（値が小さいほど遅くなる）
    });
    scroll.on('scroll',function(position) {
      var wee = $("#hero").innerHeight();
  
      if((position.scroll.y) > wee) {
        $('header').addClass('fixed');
      } else {
        $('header').removeClass('fixed');
      }
  
      var pagetop = $("#pagetop");
      if (position.scroll.y > 100) {
        pagetop.addClass("active");
      } else {
        pagetop.removeClass("active");
      }
  
      $("#pagetop").click(function (event) {
        scroll.scrollTo("top");
      });
  
    });
    */
  }




 
 

  deSVG('.desvg', true);

  var title = $(".about_title .company");
  var title2 = $(".about_title .mainen");
  var title3 = $(".about_title h1");

  gsap.fromTo(
    ".tanim",
    {
      opacity: 0,
      y: 10,
    },
    {
      opacity: 1,
      y: 0,
      duration: 0.5,
      stagger: 0.3, //space each element's animation out by 0.5 seconds
    }
  );
});

$(function () {
  if (_ua.Mobile) {
    sp_menu();
  } else {
    pc_menu();
  }

  inview();
  ipad_view();
  head_fixed_margin();
  pagetop();
  coming();
  smooth();
  faq();

});




function inview() {
  $(".inview").on("inview", function (event, visible, topOrBottomOrBoth) {
    if (visible) {
      $(this).addClass("active");
    } else {
    }
  });
}

function faq() {
  $("dl.faq dt").each(function () {
    var flag = false;
    $(this).on("click", function () {
      if (flag) {
        $(this).removeClass().next().slideUp(300);
        flag = false;
      } else {
        $(this).addClass("active").next().slideDown(300);
        flag = true;
      }
    });
  });
}

function pc_menu() {



  $(".link_btn").on("click", function () {
    event.preventDefault();
    $(this).toggleClass("active");
    $(this).next(".morelink").slideToggle();
  });

  $("#gnav_pc ul li.mainli").not('.onlymenu').on("mouseenter", function () {
    $(".overlay").addClass("active");
    $(this).addClass("active");
    $(this).siblings().removeClass("active");
    $(this).find(".megamenu_block").addClass("is-open");
    $(this).find(".megamenu_block").siblings().removeClass("is-open");
  });
  $("#gnav_pc ul li.mainli").not('.onlymenu').on("mouseleave", function () {
    $(".overlay").removeClass("active");
    $(this).removeClass("active");
    $(this).find(".megamenu_block").removeClass("is-open");
  });

  $("#spmenu").on("click", function () {
    $(this).toggleClass("active");
    $("#gnav").toggleClass("is-open");
    $("#gnav_pc").toggleClass("is-hopen");
    $("body").toggleClass("is-fixed");
    $("#logomark").toggleClass("white");
    $("header").toggleClass("isopen");
  });

  $(".gnav_flex ul li a").on("click", function () {
    $("#spmenu").removeClass("active");
    $("#gnav").removeClass("is-open");
    $("#gnav_pc").removeClass("is-hopen");
    $("body").removeClass("is-fixed");
    $("#logomark").removeClass("white");
    $("header").removeClass("isopen");
  });

  var state = false;

  $(".site_menu").on("click", function () {
    $(this).toggleClass("is-open");
    $("#gnav").toggleClass("is-open");
    $("#gnav_pc").toggleClass("is-hopen");
  });
}

var bodyElm = $("body");
var scrollPosi;
function bodyFix() {
  scrollPosi = $(window).scrollTop();
  bodyElm.css({
    position: "fixed",
    width: "100%",
    "z-index": "1",
    top: -scrollPosi,
  });
}
function head_fixed_margin() {
  var hh = $("header").innerHeight();
  //$("body").css({ "margin-top": hh + "px" });
}

function coming() {
  $(".coming a").on("click", function (event) {
    return false;
    event.preventDefault();
  });
  $(".nolink a").on("click", function (event) {
    event.preventDefault();
  });
  $("a.nolink").on("click", function (event) {
    event.preventDefault();
  });
}

function coming() {
  $(".coming a").click(function (event) {
    return false;
    event.preventDefault();
  });
  $(".nolink a").click(function (event) {
    event.preventDefault();
  });
  $("a.nolink").click(function (event) {
    event.preventDefault();
  });
}

function ipad_view() {
  if (
    ua.indexOf("iPhone") > 0 ||
    ua.indexOf("iPod") > 0 ||
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
  ) {
    $("head").prepend(
      '<meta name="viewport" content="width=device-width,initial-scale=1">'
    );
  } else if (_ua.Tablet) {
    $("head").prepend('<meta name="viewport" content="width=1280">');
    $("body").addClass("ipad_view");
  }
}

function sp_menu() {
  $("#spmenu").on("click", function () {
    $(this).toggleClass("active");
    $("#gnav").toggleClass("is-open");
    $("#gnav_pc").toggleClass("is-hopen");
    $("body").toggleClass("fixed");
    $("#logomark").toggleClass("white");
  });


   $('.scroll').perfectScrollbar({
       wheelSpeed: 2,
       wheelPropagation: true,
       minScrollbarLength: 20
   });

   $(window).resize(function() {
     $('.scroll').perfectScrollbar('update');
   });


  $(".gmain").click(function () {
  if ($(this).hasClass("dummy")) {

  } else if (!$(this).hasClass("dummy")) {
    $(this).next(".gsub").slideToggle("");
    $(this).toggleClass("active");
    $(this).next(".gsub").toggleClass("active");
  }
  });

  $(".gnav_flex ul li a").on("click", function () {

    var hh = $("header").outerHeight();

    var href = $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? "html" : href);
    // 移動先を数値で取得
    if (_ua.Mobile) {
      var position = target.offset().top - hh;
    } else if ($(this).hasClass("smin")) {
      var position = target.offset().top - hh;
    } else {
      var position = target.offset().top;
    }

    gsap.to(window, { duration: 0.5, scrollTo: position });

    
    $("#spmenu").removeClass("active");
    $("#gnav").removeClass("is-open");
    $("#gnav_pc").removeClass("is-hopen");
    $("body").removeClass("is-fixed");
    $("#logomark").removeClass("white");
    $("header").removeClass("isopen");
    $("body").removeClass("fixed").css({ top: 0 });
      window.scrollTo(0, scrollpos);

      state = false;
  });


  var state = false;

  $("#spmenu").on("click", function () {
    if (state == false) {
      scrollpos = $(window).scrollTop();
      $("body").addClass("fixed").css({ top: -scrollpos });
      $("html, body").prop({ scrollTop: scrollpos });
      state = true;
    } else {
      $("body").removeClass("fixed").css({ top: 0 });
      window.scrollTo(0, scrollpos);

      state = false;
    }
  });
}

function smooth() {
  // #で始まるアンカーをクリックした場合に処理
  $('a[href^="#"].smooth').click(function () {
    var hh = $("header").outerHeight();

    var href = $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? "html" : href);
    // 移動先を数値で取得
    if (_ua.Mobile) {
      var position = target.offset().top - hh;
    } else if ($(this).hasClass("smin")) {
      var position = target.offset().top - hh;
    } else {
      var position = target.offset().top;
    }

    gsap.to(window, { duration: 0.5, scrollTo: position });
    return false;
  });

  var h = $(window).height();
  var hh = $("header").outerHeight();
  var ss = h - hh;
  //$("section#visual").css("height" , ss);
}

function inview2() {
  //スクロール発火処理
  function scrollEffect() {
    $(".inview").each(function () {
      var scroll = $(window).scrollTop(); //現在のyスクロール量を取得
      var windowHeight = $(window).height(); //ウィンドウの高さを取得
      var tgtPos = $(this).offset().top; //ターゲットのy位置を取得
      var subjectHeight = $(this).innerHeight(); //ターゲットの高さを取得
      var threshould; //閾値
      var w = $(window).width();
      threshould = tgtPos - windowHeight - 150; //発火位置調整
      //
      if (scroll > threshould) {
        //クラスを付与する処理
        $(this).addClass("active");
      }
    });
  }
  $(window).scroll(function () {
    scrollEffect();
  });
}

function inview() {
  $(".inview").on("inview", function (event, visible, topOrBottomOrBoth) {
    if (visible) {
      $(this).addClass("active");
    } else {
    }
  });
}

function pagetop() {
  $(window).scroll(function () {
    var pagetop = $("#pagetop");
    if ($(this).scrollTop() > 100) {
      pagetop.addClass("active");
    } else {
      pagetop.removeClass("active");
    }
  });
  $("#pagetop").click(function (event) {
    gsap.to(window, { duration: 0.8, scrollTo: 0 });
  });


}

function pagetop() {
  $(window).scroll(function () {
    var pagetop = $("#pagetop");
    if ($(this).scrollTop() > 100) {
      pagetop.addClass("active");
    } else {
      pagetop.removeClass("active");
    }
  });
  $("#pagetop").click(function (event) {
    gsap.to(window, { duration: 0.8, scrollTo: 0 });
  });
  if (_ua.Mobile) {
  $(".report_list h3").on("click",function(){
    $(this).next(".repo").toggleClass("active");
  })
}else{
  
}

}

function ptop_anim() {
  var p = $("#pagetop");
  var t = $("#pagetop .inner");

  TweenMax.set(t, {
    scale: 0,
  });

  p.hover(
    function () {
      TweenMax.to(t, 0.5, {
        scale: 1.3,
        ease: Expo.easeOut,
      });
    },
    function () {
      TweenMax.to(t, 0.3, {
        scale: 0,
        ease: Expo.easeOut,
      });
    }
  );
}




// function cookieConsent() {
//   $('.btn-agree').on('click', function() {
//     $('.cookie-container').removeClass('active');
//     document.cookie("cookieBannerdisplayed");
//   });
//   $('.btn-reject').on('click', function() {
//     $('.cookie-container').removeClass('active');
//   })
//   setTimeout(function(){
//     if(!document.cookie("cookieBannerdisplayed")){
//       $('.cookie-container').addClass('active')
//     }
//   }, 1000)
//   $.cookie("abc", '123')
//   alert($.cookie("abc", '123'))
// }

function  cookieConsent() {
  var cookieBox = document.querySelector(".cookie-container");
  var acceptBtn = cookieBox.querySelector(".btn-agree");
  var rejectBtn = cookieBox.querySelector(".btn-reject");
  var language = document.getElementsByTagName('html')[0].getAttribute('lang');
  var ip = null;
  var mb = false;
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    // true for mobile device
    mb = true;
  }else{
    // false for not mobile device
    mb = false
  }
  var mbm = btoa(mb)
  
  acceptBtn.onclick = function (){
    var d = new Date(); 
    d.setTime(d.getTime() + (365*24*3600*1000));
    //d.setTime(d.getTime() + (5*60*1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = "Cookie=Save Cookie"+";"+expires+"; path=/";
    document.cookie = "lang="+btoa(language)+";"+expires+"; path=/";
    document.cookie = "checkMobile="+mbm+";"+expires+"; path=/";
    $.getJSON('https://api.db-ip.com/v2/free/self', function(data) {
      ip = btoa(data.ipAddress);
      document.cookie = "location="+ip+";"+expires+"; path=/";
    });
   if(document.cookie){
     cookieBox.classList.remove('active')
   }
  }
  rejectBtn.onclick = function (){
    var d = new Date();
    d.setTime(d.getTime() + (365*24*3600*1000));
    //d.setTime(d.getTime() + (5*60*1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = "Cookie=Cookies are not saved"+";"+expires+"; path=/";
    if(document.cookie){
      cookieBox.classList.remove('active')
    }
  }
  var checkCookie = document.cookie.indexOf("Cookie=Save Cookie");
  var checkCookie2 = document.cookie.indexOf("Cookie=Cookies are not saved");
  if(checkCookie!=-1 || checkCookie2!=-1) {
    cookieBox.classList.remove("active")
  }
  else {
    cookieBox.classList.add("active")
  }
}